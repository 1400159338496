// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
  width: 100%;
  height: 100%;
  padding: 0 3em 0 3em;
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: auto;
  grid-column-gap: 1.5em;
  grid-template-areas: " " " ";
}

.picture-container {
  grid-area: picture;
}
.picture-container img {
  border-radius: 1em;
  border: 2px solid white;
  overflow: hidden;
}
.project-container {
  grid-area: aboutText;
  overflow-y: auto;
  max-height: 75vh;
  border-radius: 1em;
  border: 2px solid white;
  padding: 2em;
  padding-bottom: 0;
  font-size: 1.25em;
}
.project-container .project-details {
  margin-left: 2em;
}
.project-container .project-details div {
  margin-top: 1em;
}
.project-container .project-details h3 {
  display: inline;
  font-size: 2em;
}
.project-container .project-details h5 {
  display: inline;
  font-size: 1.25em;
}

.quotes-container {
  grid-area: quotes;
}
.quotes-container span {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/base-components/member/member.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,oBAAA;EAEA,aAAA;EACA,8BAAA;EACA,wBAAA;EACA,sBAAA;EACA,4BACI;AADR;;AAKA;EACI,kBAAA;AAFJ;AAGI;EACI,kBAAA;EACA,uBAAA;EACA,gBAAA;AADR;AAOA;EACI,oBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;AALJ;AAMI;EACI,gBAAA;AAJR;AAKQ;EACI,eAAA;AAHZ;AAKQ;EACI,eAAA;EACA,cAAA;AAHZ;AAKQ;EACI,eAAA;EACA,iBAAA;AAHZ;;AAQA;EACI,iBAAA;AALJ;AAMI;EACI,cAAA;AAJR","sourcesContent":[".grid-container {\n    width: 100%;\n    height: 100%;\n    padding: 0 3em 0 3em;\n\n    display: grid;                          // Lets this block know to behave like a CSS grid\n    grid-template-columns: 25% 75%;         // Widths of each column\n    grid-template-rows: auto;               // Rows are set to \"auto\" to enumerate based on content height\n    grid-column-gap: 1.5em;                 // Gap betwixt columns\n    grid-template-areas:                    // Layout of all the stuff\n        \" \"\n        \" \";\n}\n\n.picture-container {\n    grid-area: picture;\n    img {\n        border-radius: 1em;\n        border: 2px solid white;\n        overflow: hidden;\n    }\n    i {\n        \n    }\n}\n.project-container {\n    grid-area: aboutText;\n    overflow-y: auto;\n    max-height: 75vh;\n    border-radius: 1em;\n    border: 2px solid white;\n    padding: 2em;\n    padding-bottom: 0;\n    font-size: 1.25em;\n    .project-details {\n        margin-left: 2em;\n        div {\n            margin-top: 1em;\n        }\n        h3 {\n            display: inline;\n            font-size: 2em;\n        }\n        h5 {\n            display: inline;\n            font-size: 1.25em;\n        }\n    }\n}\n\n.quotes-container {\n    grid-area: quotes;\n    span {\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Input, OnInit } from '@angular/core';
import { resume } from 'src/assets/configuration/global-config';
import { NavBlockInfo } from '../models/nav-block-info.model';
import { Resume } from '../models/resume.model';

@Component({
	selector: 'auto-resume',
	templateUrl: './resume.component.html',
	styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {

  @Input() info: NavBlockInfo;

  resume: Resume = resume;

	constructor() {}

	ngOnInit() {}

}

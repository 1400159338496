export interface Theme {
    [key: string]: string[];
  }
  
export interface ColorScheme {
    background: string;
    text: string;
    accent: string;
  }

export const defaultColorScheme: ColorScheme = {
  background: "bg-green-100",
  text: "fg-light-100",
  accent: "fg-dark-100"
}

export function varify(base: string): string {
  return `var(--${base})`
}

export const themes: Theme[] = [
    {
      "bg-green-100": [
        "fg-light-100",
        "fg-light-80",
        "fg-light-60",
      ]
    },{
      "bg-green-80": [
        "fg-light-100",
        "fg-light-80",
        "fg-light-60",
        "fg-dark-100"
      ]
    },
    // {
    //   "bg-green-60": [
    //     "fg-light-100",
    //     "fg-light-80",
    //     "fg-dark-100",
    //     "fg-dark-80"
    //   ]
    // },
    // {
    //   "bg-green-40": [
    //     "fg-blue-60",
    //     "fg-blue-80",
    //     "fg-blue-100",
    //     "fg-dark-100",
    //     "fg-dark-80",
    //     "fg-dark-60"
    //   ]
    // },{
    //   "bg-green-20": [
    //     "fg-blue-80",
    //     "fg-blue-100",
    //     "fg-dark-100",
    //     "fg-dark-80",
    //     "fg-dark-60",
    //   ]
    // },{
    //   "bg-green-10": [
    //     "fg-blue-100",
    //     "fg-dark-100",
    //     "fg-dark-80",
    //     "fg-dark-60",
    //     "fg-dark-40",
    //   ]
    // }
  ]
import { ShowcaseItem } from 'src/app/base-components/models/showcase-item.model'
import { Member } from 'src/app/base-components/models/member.model'
import { NavBlockInfo } from 'src/app/base-components/models/nav-block-info.model';
import { Resume } from 'src/app/base-components/models/resume.model';

export const johnathan: Member = {
	"name": "Johnathan Wood",
	"title": "Full-Stack Software Developer",
	"headline": "Software Engineer II/III, Atruce, LLC",
	"bio": "Mad scientist, Proud husband and father, PC hardware and software enthusiast"
}

export const resume: Resume = {
  name: "Johnathan Wood",
  title: "Software Developer",
  phone: "270-300-6884",
  email: "jwood95@protonmail.com",
  links: [
    {text: "GitHub", url: "https://github.com/shadowkrazee/", icon: "fa-github"},
    {text: "Linkedin", url: "https://www.linkedin.com/in/johnathan-wood-89b410151/", icon: "fa-linkedin"}
  ],
  sections: [
    {
      sectionType: "list",
      title: "Languages and Skills",
      items: [
        {content: "CompTIA Security+ certified", bulleted: true},
        {content: "AWS services, including S3, Glue, Lambda, Athena, RDS, and EC2", bulleted: true},
        {content: "Python 3", bulleted: true},
        {content: "HTML5, CSS3, SASS, Angular Framework", bulleted: true},
        {content: "TypeScript, JavaScript, Node", bulleted: true},
        {content: "REST APIs, Spring, Arest(C++), and Flask", bulleted: true},
        {content: "Arduino, PlatformIO, C++", bulleted: true},
        {content: "Java, 8 or newer", bulleted: true},
        {content: "Avid Linux user, familiar with Debian and RHEL derivitaves and Bash shell scripting", bulleted: true},
        // {content: "Jira, Microsoft TFS and GitLab experience", bulleted: true},
        {content: "Familiar with Oracle SQL, PostgreSQL, Presto", bulleted: true},
        // {content: "Jenkins Continuous Integration", bulleted: true},
        {content: "Godot game engine", bulleted: true},
        {content: "Visual Studio Code enthusiast", bulleted: true},
        // {content: "Photoshop and Illustrator", bulleted: true},
        // {content: "Android development", bulleted: true},
        // {content: "Git and Subversion VCS", bulleted: true}
      ]
    },
    {
      sectionType: "timeline",
      title: "Work Experience",
      items: [
        {
          title: "Atruce, LLC - Remote",
          subtitle: "Software Engineer II/III",
          duration: "March 2021 - PRESENT",
          highlights: [
            "Implemented mission-critical Python ETL processes working directly with customer representatives to gather requirements, resolve issues, reduce complexity, and increase efficiency.",
            "Conceived and executed improvements to fundamental components of a custom-built Python ETL framework, extending functionalities, and increasing developer efficiency.",
            "Worked as part of a team to migrate a Python ETL stack into AWS using services such as S3, Glue, Lambda, Athena, RDS, and EC2.",
            "Prepared and performed demonstrations to customers, displaying in detail the effectiveness, efficiency, and flexibility of the ETL implementation using our framework.",
          ]
        },
        {
          title: "SAIC - Fort Knox, KY",
          subtitle: "Full Stack Software Engineer",
          duration: "June 2018 - March 2021",
          highlights: [
            "Performed scrum-master duties, including assigning tasks, performing code reviews, running scrum meetings, managing multiple concurrent timelines to ensure deadlines were met and customer expectations satisfied.",
            "Implemented solutions for multiple applications, working with Key stakeholders and team members to integrate modern technologies and improve the efficiency of a globally used, mission-critical DOD application using an Angular and Spring Boot REST stack.",
            "Collaborated regularly with other teams at HRC to ensure the success of cross-application interfaces, and integrate access-control management systems such as LDAP into our application.",
            "Worked with project management and customer representatives to understand complex requirements and worked as part of a team to provide efficient, effective solutions.",
            "Used Python3 to increase efficiency by automating workplace tasks, and created internal tooling used to generate transfer object classes from Spring Data JPA objects.",
            "Experience with Oracle SQL databases, analyzing data to identify problems and using PL/SQL to generate reports.",
            "Analyzed legacy codebases to reverse-engineer requirements and plan integration into the new application, and produced mockups for customer review.",
            "Used Microsoft TFS and Jenkins to provide Continuous Integration functionalities for multiple applications",
          ]
        },
        {
          title: "Commonwealth Technology - Louisville, KY",
          subtitle: "Network Specialist / Service Technician",
          duration: "June 2016 - June 2018",
          highlights: [
            "Performed installation, networking and technical setup on newly installed Canon, Kyocera, and Konica Minolta copiers and MFPs.",
            "Worked with customers to familiarize them with the devices and implement device features to automate or optimize their workflows.",
            "Diagnosed and solved problems ranging from software malfunction to hardware maintenance or repairs.",
            "Managed logistics and scheduling to maximize efficiency, ensure maximum uptime and maintain customer satisfaction.",
            "Created a network scanning utility to find printers / copiers on the network and produce a report using SNMP data indicating the status of the devices, including paper and toner levels, and time until required maintenance.",
          ]
        },
        {
          title: "Kroger - Elizabethtown, KY",
          subtitle: "Various Positions",
          duration: "May 2013 - June 2016",
          highlights: [
            "Worked in several departments including Dairy, Meat, Cashier, and Fuel departments, and worked as a Barista at Starbucks.",
          ]
        },
        {
          title: "PC Builds / Support",
          duration: "2014 - Present",
          highlights: [
            "Built more than a dozen custom PCs for gaming and workstation tasks for various clients, and provided technical assistance and support when needed.",
          ]
        },
      ]
    },
    {
      sectionType: "list",
      title: "Education",
      items: [
        {
          title: "Elizabethtown Community and Technical College, Elizabethtown, KY",
          subtitle: "Associate in Computer and Information Technology: Programming Track",
          content: "Completed Spring 2017",
          bulleted: false
        },
        {
          title: "Southern New Hampshire University, Online",
          subtitle: "Bachelor of Science: Computer Science",
          content: "In Progress",
          bulleted: false
        }
      ]
    },
    {
      sectionType: "list",
      title: "References",
      items: [
        {
          title: "Mason Stooksbury, SAIC",
          content: "Phone: (865) 924-2041 Email: masonstooksbury@gmail.com",
          bulleted: false
        },
        {
          title: "Dan Jenkins, SAIC",
          content: "Phone: (785) 226-6440 Email: jenkinsd2013@gmail.com",
          bulleted: false
        },
        {
          title: "Justin Carpenter, SAIC",
          content: "Phone: (859) 582-1493 Email: jbcarpenter1@gmail.com",
          bulleted: false
        },
        {
          title: "Barbara Isert, Commonwealth Technology",
          content: "Phone: (859) 227-4878 Email: bisert@ctemail.net",
          bulleted: false
        }
      ]
    }
  ]
};

export const contentConfig: NavBlockInfo[] = [
  // row 1
  { navigationData: { coords: { x: -1, y: 0 }, url: 'resume' }, component: 'ResumeComponent', title: 'Resume'},
  { navigationData: { coords: { x: 0, y: 0 }, url: 'home' }, component: 'HomeComponent', title: 'Home'},
  { navigationData: { coords: { x: 1, y: 0 }, url: 'bio' }, component: 'MemberComponent', title: 'Bio'},
  // row 2
  { navigationData: { coords: { x: 0, y: 1 }, url: 'showcases' }, component: 'ShowcaseComponent', title: 'Showcases'},
  { navigationData: { coords: { x: -1, y: 1 }, url: 'showcases/software' }, component: 'ShowcaseComponent', title: 'Software'},
  { navigationData: { coords: { x: 1, y: 1 }, url: 'showcases/collabs' }, component: 'ShowcaseComponent', title: 'Collaborations'},
  // row 3 - Additional color options: '#588C79'
  { navigationData: { coords: { x: -1, y: 2 }, url: 'showcases/photos' }, component: 'ShowcaseComponent', title: 'Photos'},
  { navigationData: { coords: { x: 0, y: 2 }, url: 'showcases/dbfs' }, component: 'ShowcaseComponent', title: 'DBFS'},
  { navigationData: { coords: { x: 1, y: 2 }, url: 'showcases/3D' }, component: 'ShowcaseComponent', title: '3D'},
];

export const showcases: { [id: string]: ShowcaseItem[] } = {
	"showcases": [
    {
			"image": "",
			"title": "DBFS",
			"internalNav": "showcases/dbfs",
      "description": "A showcase of my \"Double Barrel Full Stack\" projects, built from the ground up using skills ranging from 3D design and printing, to soldering, circuits, microcontrollers, and software."
		},{
			"image": "",
			"title": "Software",
			"internalNav": "showcases/software",
      "description": "A showcase comprised of various projects I've completed over the years in the name of technological exploration and understanding, and skill development."
		},
    {
			"image": "",
			"title": "Photos",
      "internalNav": "showcases/photos",
			"description": "A showcase of some of my work as a hobbyist photographer."
		},
    {
			"image": "",
			"title": "3D",
      "internalNav": "showcases/3D",
			"description": "A showcase of 3D designs I've modeled and printed."
		},
    {
			"image": "",
			"title": "Collabs",
      "internalNav": "showcases/collabs",
			"description": "A showcase of projects I've worked on with friends."
		},
  ],
	"software": [
    {
      "image": "",
      "title": "This Website!",
      "links": ["You're already here!"],
      "description": "This website and it's unique design concepts are as much a symbol of \"out-of-the-box\" thinking as they are a functional expression of my skills and interests, and my unyeilding desire to do something new and different."
    },
    {
      "image": "",
      "title": "Time Dials",
      "links": ["Try it for yourself, <a href='https://timedials.madera.digital/' target='_blank' rel='noopener noreferrer'>here</a>!"],
      "description": "I've always been really bad at \"time awareness\", and one day I had a cool idea for a device to help me think of events and dates more accurately in terms of time. I imagined a series of dials, each showing a date or a number of years/months/days, that are coupled together in such a way that turning one dial adjusts the others such that everything remains true. While I have yet to create a physical version, I liked the idea so much I spent a weekend making a webapp POC."
    },
		{
			"image": "https://lh3.googleusercontent.com/bGbBcD0FnZGLM24zBVadEhh9afI5weTLYavcbZ8FPlXI8WbvWsRgwUbdQAaDkJ-SliKJEFMGM4n4uICrtWum5qQvr5u9mVS9icFDr0fdEzHPfBgOA4H0mgrSg3y5pnun-40DwignISr62-KJH3TBQJANCB2HgVB_S5C0xpI9LKdEVUAxfhaYiQag0bt5fOQq38r4e5katzqjsd7DdmiQw5THSN3bRhxIEZHopHlKmqtSLwGZtUcGVbQbLJpbpTGL7NF03zGUFxy5SrbJQupYQpcM5UCTrO04PkqcuNE7ZupZaaGbxSpfFv49Xt48JtdyRuNwN0UKlCnAJwwWadvdoYOhopDxEa6bOxeYtyoxUB086m9fxervAhYML0p3n9NrfNu7ERjoxq4aK-kaBW8vjnPHHy2U0GG0uiOOd3xNcEh1KN5yc-S9xRNmsDusYotyhc2UAit0v3p4dT4S7E8tM6wI4hswAMrFG9OR8g3nY0DTYUl7aucgYHWu_cAhHkTlfEYx3gzhHRvx574wlWYuXKEgD2AV22DGzRanU9ICJ4agVJayHlYJyXfxIV99pZSB_ss5NFkyn_A5nJkP3eU3qUAW6Dc9Dz1czxezAtsGLS_5hlP60_nk5LVEuMf8GRYgfpxKu41MZ1YIprgqDh1eC1LZ5_3dHZfcNJTcfUTPZaRXe2VNefBD10n5PDi26CbyShZ7qdch4-PGZQwThj0-vsNt8SZ8fDYk6ed1Q__Vc7jxzOJTmKRTycBx0suKqki8NU-FJ6d-3WfX4W6yOK14LL_oW8COrxHVF0v7H3piXGkoiVBjWuIoJSdnmrLpeVYRITP5YaFwaJNE7ak75LXcjksCgFm37vzpiK92mVxbezakJhp9KswkdRfUFqLvt_wPftPGQyGoQCZij10zikzy8NdjSIzQj-gQzWKRCE3e=w996-h980-no?authuser=0",
			"title": "HexOS",
			"links": ["Check it out on <a href='https://github.com/shadowkrazee/HEXos' target='_blank' rel='noopener noreferrer'>Github</a>!"],
			"description": "HexOS is a fork of WASP OS by Daniel Thompson, a micropython operating system for the PineTime smartwatch. I created HexOS because I wanted to create a custom binary watch face for my PineTime. Binary Clock + Hexagons = More Better."
		},
    {
			"image": "TODO: Find an image!",
			"title": "ESP-FC",
			"links": ["TODO: Add links!"],
			"description": "ESP-FC is a MicroPython firmware for controlling the spped of PC fans via a simple REST API, for easy integration using Home Assistant. The idea is to create a robust and reliable PC fan controller that doesn't require any software to run on the PC it lives in, but is independently controlled and configured. This allows use in any type of enclosure for any project with a 12v power source. This project is currently in it's POC phase, but allows for the setting and persistence of a constant speed setting for all connected fans. Future features include the addition of temperature sensors, and setting fan curves based on the values of those sensors."
		},
    {
			"image": "https://lh3.googleusercontent.com/pw/ACtC-3dNnHu2Vt-dnWgevfLBtDytZOI_Fv7QaGllDGkOgQwSM7XZzNZZiFkq3kA3V2c6_P-f0Q68Pt6e83BvwiE5kxtq0dfrB0VyMjqaLtlnp1OOmrsMn2z_VFrBsEgPYQC8PKt68J0cEY1my5ji2KUv7mcuSQ=w1756-h1097-no?authuser=0",
			"title": "Synchromancer",
			"links": ["Included in gallery for The Bourbon Light, <a href='https://photos.app.goo.gl/TRftooW172EpFfEW6' target='_blank' rel='noopener noreferrer'>here</a>!"],
			"description": "SynChroMancer is the Angular companion application to The Bourbon Light, and future synchromancer-compatible devices. It uses LocalStorage (similar to a cookie) to store the list of devices, and uses REST calls to query the device's available functions, and allows the user to select and configure the desired effects. The project is still in development, with goals including a scratch-build component library for various types of inputs, including a custom color picker!"
		},
	],
	"collabs": [
		{
			"image": "https://lh3.googleusercontent.com/pw/ACtC-3d0HCSq-lbdQp2I5DvOhy2V2F_9CMdYh6GCVXxUlVbw3yaxx4G8G2jMObboWUxeSyRxJJtSE18MPkseEAv_SYbCE-Hk-mCjBxoVLgOwGv6razHpbxEdlcBYNct77J8HaigigDZpfjjwiUM-_XXkAzZlrA=w360-h467-no?authuser=0",
			"title": "qBoard",
			"links": ["Check it out on GitHub <a href='https://github.com/shadowkrazee/qboard' target='_blank' rel='noopener noreferrer'>here</a>!"],
			"description": "Qboard was an Automages Collaboration born out of our never-ending desire to automate all the things. It's a BASH script that boosts development efficiency by allowing you to copy several things, and paste them in the order they were copied. The script uses xbindkeys, xsel, and xte, for input binding, clipboard integration, and simulating input, respectively. After configuring the bindings, you can copy any number of various blocks of text \"into the queue\", and then paste them back out in the same order. Under the hood, the script uses a file to store the \"queue\" so it will even work across reboots!, "
		},
    {
      "image": "",
      "title": "More on the way!",
      // "links": ["Check out the gallery <a href='https://photos.app.goo.gl/TRftooW172EpFfEW6' target='_blank' rel='noopener noreferrer'>here</a>!"],
      "description": "Documentation for other collaborations is underway!"
    }
	],
    "photos": [
      {
        "image": "",
        "title": "35mm Film",
        // "links": ["Check out the gallery <a href='https://photos.app.goo.gl/TRftooW172EpFfEW6' target='_blank' rel='noopener noreferrer'>here</a>!"],
        "description": "Lacking the funds for modern DSLRs early in my photography journey, I discovered film photography as a cheaper alternative, and quickly fell in love with the retro hardware, more manual contol, and the character that comes with film. Here's a collection of my favorite shots on film."
      },
      {
        "image": "",
        "title": "Trips and Travel",
        // "links": ["Check out the gallery <a href='https://photos.app.goo.gl/TRftooW172EpFfEW6' target='_blank' rel='noopener noreferrer'>here</a>!"],
        "description": "Some digital photos from my travels around the US, and abroad. Hopefully more to come soon!"
      },
      {
        "image": "",
        "title": "Exposure Magic",
        // "links": ["Check out the gallery <a href='https://photos.app.goo.gl/TRftooW172EpFfEW6' target='_blank' rel='noopener noreferrer'>here</a>!"],
        "description": "Let's do the time warp again! Some of my favorite photos using either long or short exposures, capturing things from a different perspective."
      }
    ],
    "dbfs": [
      {
        "image": "https://lh3.googleusercontent.com/0xxGk-Kk-B0IKEAm9VE_DzKDscxk1kMiI4FwHh6ApRTqkdMOVsXPvBQLqWQC5atrmqxOM8zRsDml2gxnEXYL6dvQNnX_Xim9OnrWJ4Y26ofyxTRuBqopKUIFNixrP0xE6A_5n6TYlmDMxSUe14CIoXdEflYcFvir10D_BL4ANYm_frgJUApojNade_3UtsjFuWNE_kd3qx22PNYS2lMCzt6qmE2mjoAlady-dW95ZNMbp3yM7za2dyCUbb0b__8Fir0joApM9051bJpmG2z2Hw2Uy-pyvsCDAW-_I_WYdwJmDCT8u7A0JpSSKK3Ryv58I9R-iEWDLZnB919ZeKP3qz9xihLY3nrixsxtNwpav1rTU4Ko_h6mEzXXzkP1X-RFgtiiygCYGoEtVmlPpp3h_SB7l7sfqTQ2FyqoL43EX094xOI_Q04ds_QNHPPsXZe3Nuhd2pi6KfHpyuKVzD-ZXqcbycQgWp5katLzyGspmx-4hW1Dmg4aWUWvOJgUxW5E5U896CSALAYd22-cwYRLebQLe5Koo6BXOcKDcnNm_1JLGpFzmlX_wNozMZKVDw-u9HP7hOWr8HZuC4TjbqeP85qrXqPz_9r4HmoIGGpYeE3jD_Op79E4oYzDqfgFxpCoaoC9wa2gbmuSYDql1S-iPPfawRkMZ9eubqIyNphioTpZE2I459kbB7vYLq3oZlca3i8tLaotZLWKfzAv81yCixOgLrtLWX6X71p-t_X4hqC-WHbgzKMxxnOLJvzqYPePC0TNlp-loIijAyKpJ6S_zb3MJeWDg2oLfHaLZqecN3Ny6bEi8j4gz9fH3lFrl9SAEL9PqbU-UzD1heaOZTUPzdX68qzHY_bIfzFly9ccdR36jll5-3x8q3vU7JahmjiITlkXCu-9jyZ5TfHkjPaUDuFe6rTGKWMNHrYmTMRK=w1307-h981-no?authuser=0",
        "title": "HexOS Binary Wall Clock",
        "links": ["Check out the gallery <a href='https://photos.app.goo.gl/KwmqYTM9W1KMLUZg7' target='_blank' rel='noopener noreferrer'>here</a>!"],
        "description": "I loved my hexagon-themed binary watch face for the PineTime so much, I made one for the wall! From from the 3D models and printed parts, to the circuitry and software, this was truly a \"Double-Barrel Full-Stack\" project."
      },
      {
        "image": "https://lh3.googleusercontent.com/pw/ACtC-3fvC9AA7-LuxpCrTRjd6-YlyoN0VtQ_tmW_WNn1TISakUcgxn4s04Oozs0Egna60CyCGXfgcQ2zH-hA8p5GKr2nNhrupDL7zJLrxxctP-wbTjOq9fs-tS6vgdVlNIGK6dClvczOsVrKU5LtRdpuj-GiCA=w823-h1097-no?authuser=0",
        "title": "The Bourbon Light",
        "links": ["Check out the gallery <a href='https://photos.app.goo.gl/TRftooW172EpFfEW6' target='_blank' rel='noopener noreferrer'>here</a>!"],
        "description": "The Bourbon Light is an Angel's Envy themed chandelier made from an authentic oak barrel top, 4 Angel's Envy bottles, 64 WS2812b addressable RGB LEDs, a custom interface PCB with 3D-printed case, and an ESP32 microcontroller running custom software to handle wifi connection and LED control. This project was quite the endeavor, as I DIY'd everything from soldering the PCB, wiring the LEDs to sanding, staining, and woodburning the barrel top, drilling the holes in the bottles, and writing the c++ control software, and the Angular  10 companion application."
      }
    ],
    "3D": [
      {
        "image": "https://lh3.googleusercontent.com/pw/ACtC-3dChP2hHKxH6krN8-wJAWEg73xjaXlj_RbEWidASSmsEdamYktD_O9F2JU4UGJcxLHUyJ5xcw_6iXMvanJ2SEVII-kNibz5X6v-iqcRqxSqWAoZitlja8IGoT8xmw4SfwYZJcJ6I45t58nyxSUq6WKXXA=w823-h1097-no?authuser=0",
        "title": "Custom 3D Printed Guitar Pickups",
        "links": ["See the process in the gallery, <a href='https://photos.app.goo.gl/1Xb2ktPR5BU5Y9MB7' target='_blank' rel='noopener noreferrer'>here</a>!"],
        "description": "After years of curiosity about what makes an electric guitar really tick, I decided to try my hand at making my own pickups. While I can't really play, I've always appreciated the craftsmanship and science behind guitars, and I learned alot over the course of this project. I began by tearing down an old fishing reel I picked up for $5 at the local peddler's mall, and modeling some new pieces that would allow me to attach different coupler pieces to fit various bobbins. I also attached a photo-interrupter, which combined with a bit of code and an Arduino, allowed me to count the number of wraps on the coils. After days of design work in Fusion 360, I had a couple prototype designs to try out. There were several iterations, and even a humbucker design! While the end product may not sound as nice as some high-end pickups, they're one-of-a-kind, and not many people can say they've made their own pickups."
      },
      {
        "image": "",
        "title": "The TronXYdent",
        // "links": ["Check out the gallery <a href='https://photos.app.goo.gl/TRftooW172EpFfEW6' target='_blank' rel='noopener noreferrer'>here</a>!"],
        "description": "My very heavily modified printer, made from the bones of a TronXY X5SA Pro 400, Klipper-ized using a BTT Octopus + Raspberry Pi, and smashed together with the XY kinematics of the Voron Trident, a custom AC heated print bed, and a custom-built electronics enclosure."
      },
      {
        "image": "",
        "title": "Future Content!",
        // "links": ["Check out the gallery <a href='https://photos.app.goo.gl/TRftooW172EpFfEW6' target='_blank' rel='noopener noreferrer'>here</a>!"],
        "description": "Documentation efforts are underway for more 3D projects!"
      }
    ]
}

import { Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationData, NavigationService } from 'src/app/services/navigation.service';
import { HomeComponent } from '../home/home.component';
import { MemberComponent } from '../member/member.component';

import { NavBlockInfo, Tile } from '../models/nav-block-info.model';
import { ResumeComponent } from '../resume/resume.component';
import { ShowcaseComponent } from '../showcase/showcase.component';

@Component({
	selector: 'auto-nav-block',
	templateUrl: './nav-block.component.html',
	styleUrls: ['./nav-block.component.scss']
})
export class NavBlockComponent implements OnInit {

	// will contain a place to put the component provided in info
	// The sauce is in the link below
	// https://stackoverflow.com/questions/44939878/dynamically-adding-and-removing-components-in-angular

	@Input() info: NavBlockInfo;
	@ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  types = {
    'HomeComponent': HomeComponent,
    'MemberComponent': MemberComponent,
    'ResumeComponent': ResumeComponent,
    'ShowcaseComponent': ShowcaseComponent,
  }
	simple = true;
	isMobile = false;

	componentInstance;

	constructor(private navService: NavigationService) { }

	ngOnInit() {
		this.navService.registerTile(this.info.navigationData);
		// TODO: figure out how to remove this timeout without changedafterchecked errors
		this.navService.currentTarget.subscribe((t) => setTimeout(() => this.needsInit(t) ? this.initComponent() : this.destroyComponent()));
		this.navService.isMobile.subscribe((isMobile) => this.isMobile = isMobile);
	}

	needsInit(t: NavigationData): boolean {
		if (this.info.navigationData.url === t.url) return true;
		if (t.neighbors) return Object.keys(t.neighbors).some((key) => t.neighbors[key] && t.neighbors[key].url === this.info.navigationData.url);
		return false;
	}

	// create the component and render it
	initComponent() {
		this.simple = false;
		if (this.container && !this.componentInstance) {
			this.componentInstance = this.container.createComponent(this.types[this.info.component]);
			(<Tile>this.componentInstance.instance).info = this.info;
		}
	}

	// destroy the component and switch back to "simple mode"
	// depending on memory overhead, this may not be needed
	destroyComponent() {
		this.simple = true;
	}

}

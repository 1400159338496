import { Component, OnInit } from '@angular/core';

import { NavigationService } from './services/navigation.service';

@Component({
	selector: 'auto-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	// constructor(private router: Router, private navService: NavigationService) {}
	constructor(private navService: NavigationService) { }

	ngOnInit() {
		// this.navService.url.subscribe((url) => this.router.navigateByUrl(url));
	}
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { contentConfig } from 'src/assets/configuration/global-config';

import { HomeComponent } from '../home/home.component';
import { MemberComponent } from '../member/member.component';
import { NavBlockInfo } from '../models/nav-block-info.model';
import { ResumeComponent } from '../resume/resume.component';
import { ShowcaseComponent } from '../showcase/showcase.component';
import { ColorScheme, defaultColorScheme, themes, varify } from '../models/theme.model';

@Component({
  selector: 'auto-nav-grid',
  templateUrl: './nav-grid.component.html',
  styleUrls: ['./nav-grid.component.scss']
})
export class NavGridComponent implements OnInit, AfterViewInit {

  redirect: boolean = false;

  grid: any = {};

  contentInfo = contentConfig.map((b) => {
    return Object.assign(b, {colorScheme: this.getColorScheme()})
  });

  constructor(private navService: NavigationService) { }

  ngOnInit() {
    this.makeGrid();
    this.navService.setBlockInfo({ grid: this.grid, tiles: this.contentInfo });
    window.onpopstate = (event) => {
      console.log(event);
      this.navService.popState(event);
    }
  }

  ngAfterViewInit() {
    this.parseURL();
  }

  makeGrid() {
    const grid = {};
    let minX = 0, maxX = 0, minY = 0, maxY = 0;
    console.log(this.contentInfo);
    this.contentInfo.forEach((block) => {
      minX = block.navigationData.coords.x < minX ? block.navigationData.coords.x : minX;
      maxX = block.navigationData.coords.x > maxX ? block.navigationData.coords.x : maxX;
      minY = block.navigationData.coords.y < minY ? block.navigationData.coords.y : minY;
      maxY = block.navigationData.coords.y > maxY ? block.navigationData.coords.y : maxY;
    });
    grid['xTiles'] = Math.abs(maxX) + Math.abs(minX) + 1;
    grid['yTiles'] = Math.abs(maxY) + Math.abs(minY) + 1;
    const wrapper: HTMLElement = document.getElementById('grid-wrapper');
    wrapper.style.height = `calc(${grid['yTiles']} * 100vh)`;
    wrapper.style.width = `calc(${grid['xTiles']} * 100vw)`;
    this.grid = grid;
  }

  // "finds" the target tile, and requests it from the navigationService.
  parseURL() {
    console.log('parsing URL...');
    const segments = window.location.href.split('/');
    const startIndex = segments.findIndex((s) => (/automages.dev/.test(s) || /localhost:4200/.test(s) || /madera.digital/.test(s))) + 1;
    let relativeUrl = segments.slice(startIndex).join('/');
    // if target is /projects or /collabs, strip off the ID and save it
    // do not include in matching
    if (relativeUrl === '') relativeUrl = 'home';
    console.log(`Parsed URL: ${relativeUrl}`);
    // TODO: Improve or remove fuzzy-matching feature. Existing implementations is pretty hit-or-miss
    const matchMap = this.contentInfo.map((t) => {
      const reg = new RegExp((t.navigationData.url.split('').join('?') + '?').replace(/\//, '\\\/'));
      const matchScore = reg.exec(relativeUrl)['0'].length;
      return { url: t.navigationData.url, matchScore };
    });
    console.log('Matching Requested URL');
    console.log(matchMap);
    const target =
      this.contentInfo.find((c) =>
        matchMap.find((x) => Math.max(...matchMap.map((m) => m.matchScore))
          === x.matchScore).url
        === c.navigationData.url);
    this.navService.requestURL(target.navigationData.url);
  }

  getColorScheme(): ColorScheme {
    // if (random) {
    //   const randomTheme = themes[Math.floor(Math.random() * themes.length)];
    //   const background = Object.keys(randomTheme)[0];
    //   const colors = randomTheme[background];
    //   // Shuffle the colors array
    //   const shuffledColors = [...colors].sort(() => Math.random() - 0.5);
    //   // Take the first two colors for text and accent
    //   const [text, accent] = shuffledColors.slice(0, 2);
    // }
    const cs = defaultColorScheme;
  
    return { background: varify(cs.background), text: varify(cs.text), accent: varify(cs.accent) };
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'auto-collab',
	templateUrl: './collab.component.html',
	styleUrls: ['./collab.component.scss']
})
export class CollabComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HomeComponent } from '../base-components/home/home.component';
import { NavBlockInfo } from '../base-components/models/nav-block-info.model';
import { XYCoordinate } from '../base-components/models/x-y-coordinate.model';

export interface NavigationData {
	// XYCoordinate pair for the target component
	coords: XYCoordinate;
	// The relative portion of the URL for the target
	url: string;
	// An object containing any north, south, east, and west "neighbors" for the target
	neighbors?: {
		north: NavigationData | null,
		south: NavigationData | null,
		east: NavigationData | null,
		west: NavigationData | null
	};
}

@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	// Keep this updated with the data from the "home" tile
	// http://localhost:4200/johnathan
	private defaultNavData = {
		coords: { x: 0, y: 0 },
		url: 'home',
		component: HomeComponent,
		title: 'Madera.Digital',
		color: '#21BADB'
	};
	// Keeps the current tile's navData
	// viewport subscribes to this to handle navigation
	currentTarget = new BehaviorSubject<NavigationData>(this.defaultNavData);
	currentTargetColor = new BehaviorSubject<string>('#21BADB');
	// detect whether or not this is a mobile user
	isMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent));
	gridInfo = new BehaviorSubject<{ grid: any, tiles: NavBlockInfo[] }>({ grid: {}, tiles: [] })

	registeredTiles: NavigationData[] = [];
	blockInfo: NavBlockInfo[] = [];
	stateId = 0;

	private currentURL: string;
	private lastURL: string;

	constructor() { }

	// Called by the grid component onInit, after the URL is parsed
	setBlockInfo(info: { grid: any, tiles: NavBlockInfo[] }) {
		this.blockInfo = info.tiles
		this.gridInfo.next(info);
	}


	// request navigation to a tile by url
	// If the requested target is invalid, or no navigation is needed, return a null observable
	// Otherwise, returns NavigationData for the target
	requestURL(url: string) {
		console.log(`requestURL: ${url}`);
		this.updateCurrentTarget(url);
	}

	// Bound to the onPopState event in nav-grid
	// Can be called to return the user to the previously viewed tile
	popState(state) {
		console.log('popState');
		this.updateCurrentTarget(this.lastURL, false);
	}

	// request navigation to a tile by coordinates (via button click)
	// If the requested target is invalid, or no navigation is needed, return a null observable
	// Otherwise, returns NavigationData for the target
	requestTile(coords: XYCoordinate) {
		console.log('requestTile');
		const url = this.blockInfo.find((b) => b.navigationData.coords.x === coords.x && b.navigationData.coords.y === coords.y).navigationData.url;
		this.updateCurrentTarget(url);
	}

	// Called by each gridBlock onInit
	// Adds a new "NavigationData" to "registeredTiles"
	// Updates the existing list with any newly detected neighbors
	registerTile(data: NavigationData) {
		// console.log(`registered url: ${data.url}`);
		this.registeredTiles.push(data);
		this.calculateNeighbors();
	}

	private updateCurrentTarget(url: string, updateHistory = true) {
		const match = this.blockInfo.find((b) => b.navigationData.url === url);
		if (match) {
			this.lastURL = this.currentURL;
			this.currentURL = match.navigationData.url;
      document.title = `Madera.digital | ${match.title}`;
			if (updateHistory) {
				console.log(`Pushed: ${this.lastURL} to history.`);
				window.history.pushState({ previousURL: this.lastURL }, 'Madera.digital', match.navigationData.url);
			}
			this.currentTarget.next(match.navigationData);
			// this.currentTargetColor.next(match.color);
		}
	}

	private calculateNeighbors() {
		this.registeredTiles.forEach((rt) => {
			const toNorth = this.registeredTiles.find((ot) => (ot.coords.x === rt.coords.x && ot.coords.y === rt.coords.y - 1));
			const toSouth = this.registeredTiles.find((ot) => (ot.coords.x === rt.coords.x && ot.coords.y === rt.coords.y + 1));
			const toEast = this.registeredTiles.find((ot) => (ot.coords.y === rt.coords.y && ot.coords.x === rt.coords.x + 1));
			const toWest = this.registeredTiles.find((ot) => (ot.coords.y === rt.coords.y && ot.coords.x === rt.coords.x - 1));
			rt.neighbors = {
				north: toNorth ? toNorth : null,
				south: toSouth ? toSouth : null,
				east: toEast ? toEast : null,
				west: toWest ? toWest : null,
			};
		});
	}
}

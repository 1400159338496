import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HeaderBarComponent } from './base-components/header-bar/header-bar.component';
import { HomeComponent } from './base-components/home/home.component';
import { MemberComponent } from './base-components/member/member.component';
import { NavBlockComponent } from './base-components/nav-block/nav-block.component';
import { NavGridComponent } from './base-components/nav-grid/nav-grid.component';
import { ResumeComponent } from './base-components/resume/resume.component';
import { MinimapComponent } from './base-components/viewport/minimap/minimap.component';
import { MyHammerConfig, ViewportComponent } from './base-components/viewport/viewport.component';
import { CollabComponent } from './collab/collab.component';
import { ShowcaseComponent } from './base-components/showcase/showcase.component';

@NgModule({
    declarations: [
        AppComponent,
        MemberComponent,
        CollabComponent,
        NavBlockComponent,
        NavGridComponent,
        ViewportComponent,
        HeaderBarComponent,
        HomeComponent,
        ResumeComponent,
        MinimapComponent,
        ShowcaseComponent
    ],
    imports: [BrowserModule, HttpClientModule, HammerModule],
    providers: [{ provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }],
    bootstrap: [AppComponent]
})
export class AppModule { }

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
}

#grid-wrapper {
  position: relative;
  transition: top 1s ease-in-out, left 1s ease-in-out;
}

.nav-block {
  position: absolute;
}
.nav-block.x-10 {
  left: -1000vw;
}
.nav-block.x-9 {
  left: -900vw;
}
.nav-block.x-8 {
  left: -800vw;
}
.nav-block.x-7 {
  left: -700vw;
}
.nav-block.x-6 {
  left: -600vw;
}
.nav-block.x-5 {
  left: -500vw;
}
.nav-block.x-4 {
  left: -400vw;
}
.nav-block.x-3 {
  left: -300vw;
}
.nav-block.x-2 {
  left: -200vw;
}
.nav-block.x-1 {
  left: -100vw;
}
.nav-block.x0 {
  left: 000vw;
}
.nav-block.x1 {
  left: 100vw;
}
.nav-block.x2 {
  left: 200vw;
}
.nav-block.x3 {
  left: 300vw;
}
.nav-block.x4 {
  left: 400vw;
}
.nav-block.x5 {
  left: 500vw;
}
.nav-block.x6 {
  left: 600vw;
}
.nav-block.x7 {
  left: 700vw;
}
.nav-block.x8 {
  left: 800vw;
}
.nav-block.x9 {
  left: 900vw;
}
.nav-block.x10 {
  left: 1000vw;
}
.nav-block.y-10 {
  top: -1000vh;
}
.nav-block.y-9 {
  top: -900vh;
}
.nav-block.y-8 {
  top: -800vh;
}
.nav-block.y-7 {
  top: -700vh;
}
.nav-block.y-6 {
  top: -600vh;
}
.nav-block.y-5 {
  top: -500vh;
}
.nav-block.y-4 {
  top: -400vh;
}
.nav-block.y-3 {
  top: -300vh;
}
.nav-block.y-2 {
  top: -200vh;
}
.nav-block.y-1 {
  top: -100vh;
}
.nav-block.y0 {
  top: 000vh;
}
.nav-block.y1 {
  top: 100vh;
}
.nav-block.y2 {
  top: 200vh;
}
.nav-block.y3 {
  top: 300vh;
}
.nav-block.y4 {
  top: 400vh;
}
.nav-block.y5 {
  top: 500vh;
}
.nav-block.y6 {
  top: 600vh;
}
.nav-block.y7 {
  top: 700vh;
}
.nav-block.y8 {
  top: 800vh;
}
.nav-block.y9 {
  top: 900vh;
}
.nav-block.y10 {
  top: 1000vh;
}`, "",{"version":3,"sources":["webpack://./src/app/base-components/nav-grid/nav-grid.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mDAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;AACQ;EAAW,aAAA;AAEnB;AAFQ;EAAW,YAAA;AAKnB;AALQ;EAAW,YAAA;AAQnB;AARQ;EAAW,YAAA;AAWnB;AAXQ;EAAW,YAAA;AAcnB;AAdQ;EAAW,YAAA;AAiBnB;AAjBQ;EAAW,YAAA;AAoBnB;AApBQ;EAAW,YAAA;AAuBnB;AAvBQ;EAAW,YAAA;AA0BnB;AA1BQ;EAAW,YAAA;AA6BnB;AA7BQ;EAAW,WAAA;AAgCnB;AAhCQ;EAAW,WAAA;AAmCnB;AAnCQ;EAAW,WAAA;AAsCnB;AAtCQ;EAAW,WAAA;AAyCnB;AAzCQ;EAAW,WAAA;AA4CnB;AA5CQ;EAAW,WAAA;AA+CnB;AA/CQ;EAAW,WAAA;AAkDnB;AAlDQ;EAAW,WAAA;AAqDnB;AArDQ;EAAW,WAAA;AAwDnB;AAxDQ;EAAW,WAAA;AA2DnB;AA3DQ;EAAW,YAAA;AA8DnB;AA3DQ;EAAW,YAAA;AA8DnB;AA9DQ;EAAW,WAAA;AAiEnB;AAjEQ;EAAW,WAAA;AAoEnB;AApEQ;EAAW,WAAA;AAuEnB;AAvEQ;EAAW,WAAA;AA0EnB;AA1EQ;EAAW,WAAA;AA6EnB;AA7EQ;EAAW,WAAA;AAgFnB;AAhFQ;EAAW,WAAA;AAmFnB;AAnFQ;EAAW,WAAA;AAsFnB;AAtFQ;EAAW,WAAA;AAyFnB;AAzFQ;EAAW,UAAA;AA4FnB;AA5FQ;EAAW,UAAA;AA+FnB;AA/FQ;EAAW,UAAA;AAkGnB;AAlGQ;EAAW,UAAA;AAqGnB;AArGQ;EAAW,UAAA;AAwGnB;AAxGQ;EAAW,UAAA;AA2GnB;AA3GQ;EAAW,UAAA;AA8GnB;AA9GQ;EAAW,UAAA;AAiHnB;AAjHQ;EAAW,UAAA;AAoHnB;AApHQ;EAAW,UAAA;AAuHnB;AAvHQ;EAAW,WAAA;AA0HnB","sourcesContent":[":host {\r\n    position: relative;\r\n}\r\n\r\n#grid-wrapper {\r\n    position: relative;\r\n    transition: top 1s ease-in-out, left 1s ease-in-out;\r\n}\r\n\r\n.nav-block {\r\n    position: absolute;\r\n    @for $x from -10 through 10 {\r\n        &.x#{$x} { left: #{$x}00vw; } \r\n    }\r\n    @for $y from -10 through 10 {\r\n        &.y#{$y} { top: #{$y}00vh; } \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.pnpm/css-loader@6.10.0_webpack@5.90.3_esbuild@0.20.1_/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-wrapper {
  width: 100vw;
  height: 100vh;
  color: var(--default-text);
  position: relative;
}

.simple-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.simple-overlay.active {
  display: flex;
}
.simple-overlay h1, .simple-overlay p {
  display: inline;
}

.component-wrapper {
  padding: 4em;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.component-wrapper.mobile {
  padding: 1em;
}
.component-wrapper h1, .component-wrapper p {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/app/base-components/nav-block/nav-block.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EAEA,0BAAA;EAEA,kBAAA;AADJ;;AAMA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EAEA,qBAAA;EACA,uBAAA;EACA,mBAAA;AAJJ;AACI;EAAW,aAAA;AAEf;AAEI;EACI,eAAA;AAAR;;AAIA;EACI,YAAA;EAEA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;AAJI;EAAU,YAAA;AAOd;AAAI;EACI,eAAA;AAER","sourcesContent":[".block-wrapper {\r\n    width: 100vw;\r\n    height: 100vh;\r\n    // background-color: #1e1e1e;\r\n    color: var(--default-text);\r\n    // background-color: #1e1e1e;\r\n    position: relative;\r\n    // border-radius: 1em;\r\n    // border: 2px solid #4a2b10;\r\n}\r\n\r\n.simple-overlay {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    height: 100%;\r\n    width: 100%;\r\n    display: none;\r\n    &.active { display: flex; }\r\n    flex-flow: row nowrap;\r\n    justify-content: center;\r\n    align-items: center;\r\n    h1, p {\r\n        display: inline;\r\n    }\r\n}\r\n\r\n.component-wrapper {\r\n    padding: 4em;\r\n    &.mobile {padding: 1em;}\r\n    height: 100%;\r\n    width: 100%;\r\n    overflow: hidden;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    h1, p {\r\n        display: inline;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

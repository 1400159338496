import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'auto-header-bar',
	templateUrl: './header-bar.component.html',
	styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}

import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators'
import { NavigationService } from 'src/app/services/navigation.service';
import { showcases } from 'src/assets/configuration/global-config';
import { NavBlockInfo } from '../models/nav-block-info.model';

@Component({
  selector: 'auto-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit, AfterViewInit {

  @Input() info: NavBlockInfo;

  resizeEvent: Observable<Event>;

  showcaseName: string;

  showcaseItems = [];

  groupSize = 3;

  trackElement: HTMLElement;
  holderWidth: number;
  scrollPosition: number = 0;
  maxScrollPosition: number = 1;

  constructor(private navService: NavigationService) { }

  // register a handler for the resize event to recalculate the group size for the showcase
  calculateGroupSize() {
    // reset current scroll position
    this.trackElement.style.left = '0px';
    this.holderWidth = document.getElementById(`${this.showcaseName}-showcase-holder`).clientWidth;
      let tempGS = Math.floor(this.holderWidth / 650) + 1;
      tempGS = tempGS <= 4 ? tempGS : 4;
      this.groupSize = tempGS;
      // also update the maxScrollPosition
      this.maxScrollPosition = this.showcaseItems.length > 0 ? Math.ceil(this.showcaseItems.length / this.groupSize -1) : 0;
  }

  // Determines whether or not a scroll button should be shown for each side
  isActive(side: 'left' | 'right') {
    if (this.trackElement) {
      return side === 'left' ? !(this.scrollPosition === 0) : !(this.scrollPosition === this.maxScrollPosition)
    } else {
      return true;
    }
  }

  scroll(dir: 'left' | 'right') {
    const dirVal = dir === 'left' ? -1 : 1;
    // Calculate the new value
    const newVal = this.holderWidth * (this.scrollPosition + dirVal)
    // Check bounds, then update scroll position, if legal
    if ( newVal >= 0 && newVal < this.holderWidth * (this.showcaseItems.length / this.groupSize)) {
      this.scrollPosition += dirVal;
      this.trackElement.style.left = `calc(-${this.holderWidth} * ${this.scrollPosition}px)`;
    }
    // console.log(`${this.scrollPosition}/${this.maxScrollPosition}`);
  }

  navigate(relUrl: string) {
    this.navService.requestURL(relUrl);
  }

  ngOnInit(): void {
    // Parse the url and grab the last bit, we can use this to determine which chowcase we want to display
    const segments = this.info.navigationData.url.split('/');
    this.showcaseName = segments.slice(-1)[0];
    this.showcaseItems = showcases[this.showcaseName];
  }

  ngAfterViewInit(): void {
    this.trackElement = document.getElementById(`${this.showcaseName}-showcase-track`);
    this.calculateGroupSize();

    this.resizeEvent = fromEvent(window, 'resize');
    this.resizeEvent.pipe(debounceTime(250)).subscribe( e => {
      this.calculateGroupSize()
    });
  }


}

import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'auto-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	milestones: { title: string, complete: boolean }[] = [
		{ title: 'Configuration-based site layout, content, components, and theme sourced from a single config file.', complete: true },
		{ title: 'Migration to Angular 18 + Server-Side Rendering! (In progress)', complete: false },
		{ title: 'Site-wide UI/UX enhancement (In progress)', complete: false },
		{ title: 'Scratch-built Angular routing system', complete: true },
		{ title: 'Uniqie tile-based layout and navigation', complete: true },
		{ title: 'Dynamic arrow buttons for desktop navigation', complete: true },
		{ title: 'Swipe navigation for mobile', complete: true },
		{ title: 'Dynamic content and layout generation based on JSON configuration', complete: true },
		{ title: 'Dynamic tile sizing to match any display', complete: true },
		{ title: 'Memory-saving dynamic component loading', complete: true },
		{ title: 'Animated hamburger menu for more traditional navigation', complete: true },
		{ title: 'Minimap to show site structure with correct scale (in beta, try resizing the browser window!)', complete: true },
		{ title: 'Generic carousel-like "showcase" component, for displaying things around the site', complete: true },
		{ title: 'Dynamically generated Resume page from JSON configuration (In Progress!)', complete: false },
		{ title: 'Minimap refinement and optimization', complete: false },
		{ title: 'Further refinement and optimization for mobile experience', complete: false }
	]

	constructor() { }

	ngOnInit() {
	}

}

import { Component, Injectable, OnInit } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { NavigationData, NavigationService } from 'src/app/services/navigation.service';
import { defaultColorScheme, varify } from '../models/theme.model';

export interface NavTreeNode {
	title: string;
	root: string;
	url: string;
	isActive: boolean;
	expandChildren: boolean;
	children: NavTreeNode[];
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
	overrides = { swipe: { direction: Hammer.DIRECTION_ALL } };
}

@Component({
	selector: 'auto-viewport',
	templateUrl: './viewport.component.html',
	styleUrls: ['./viewport.component.scss']
})
export class ViewportComponent implements OnInit {

	grid: HTMLElement;
	currentTile: NavigationData;
	navTree: NavTreeNode[] = [];
	isMobile: boolean = false;
	isNavigating = true;
	menuActive = false;

	menuAnimationState = 'closed';
	textColor = varify(defaultColorScheme.background);
	bgColor = varify(defaultColorScheme.text);
	constructor(private navService: NavigationService) { }

	ngOnInit() {
		this.grid = document.getElementById('grid-wrapper');
		// TODO replace these with defaults calculated based on "target" of url
		// this.grid.style.left = '0px';
		// this.grid.style.top = '0px';
		// subscribe to the navigationService's "target"
		this.navService.currentTarget.subscribe((t) => setTimeout(() => this.navigate(t)));
		// this.navService.currentTargetColor.subscribe((color) => this.setButtonColors(color));
		this.navService.isMobile.subscribe((isMobile) => this.isMobile = isMobile);
	}

	onSwipe(event: any) {
		console.log('swipe!');
		const dirs = [
			{ dir: 'north', swipe: 16 },
			{ dir: 'south', swipe: 8 },
			{ dir: 'east', swipe: 2 },
			{ dir: 'west', swipe: 4 }
		];
		const swipeDirection = dirs.find((d) => event.direction === d.swipe);
		if (swipeDirection && this.currentTile.neighbors[swipeDirection.dir]) this.moveDir(swipeDirection.dir);
	}

	private navigate(data: NavigationData) {
		this.currentTile = data;
		this.isNavigating = true;
		this.grid.style.left = `calc(${data.coords.x} * -100vw)`;
		this.grid.style.top = `calc(${data.coords.y} * -100vh)`;
		// this timeout matches the animation length for #grid-wrapper in nav-grid scss
		setTimeout(() => this.isNavigating = false, 1000);
		this.updateNavMenu();
	}

	moveDir(dir: string) {
		if (this.currentTile.neighbors[dir]) {
			this.move(this.currentTile.neighbors[dir].url);
		}
	}

	move(url: string) {
		this.navService.requestURL(url);
	}

	updateNavMenu() {
		this.navTree = [];
		this.navService.blockInfo.forEach((t) => {
			const node = <NavTreeNode>{
				title: t.title,
				root: t.navigationData.url.split('/')[0],
				url: t.navigationData.url,
				isActive: false,
				children: [],
				expandChildren: false
			};
			const parent = this.navTree.find((n) => (n.root === node.root));
			(!!parent ? parent.children : this.navTree).push(node);
		});
		this.setActiveNode();
	}

	setActiveNode() {
		this.navTree.forEach((node) => {
			if (node.url === this.currentTile.url) {
				node.isActive = true;
			}
			const splitTarget = this.currentTile.url.split('/');
			const splitNodeURL = node.url.split('/');
			if (node.children.length > 0 && splitTarget[0] === splitNodeURL[0]) {
				node.expandChildren = true;
				node.children.forEach((child) => {
					if (child.url === this.currentTile.url) {
						child.isActive = true;
					}
				});
			}
		});
	}

	navClick(node: NavTreeNode, home = false) {
		if (node.children.length === 0 || home) {
			const target = this.navService.blockInfo.find(b => (b.navigationData.url === node.url));
			this.move(target.navigationData.url);
			this.toggleMenu(false);
		} else {
			// close any other open groups
			this.navTree.filter((n) => (n.url !== node.url))
				.forEach((t) => t.expandChildren = false);
			// toggle the source node's "expand" state
			node.expandChildren = !node.expandChildren;
		}
	}

	toggleMenu(state?: boolean) {
		this.menuActive = state !== undefined ? state : !this.menuActive;
		this.menuAnimationState = this.menuActive ? 'opening' : 'closing';
		setTimeout(() => { this.menuAnimationState = this.menuActive ? 'open' : 'closed'; }, 500);
	}

	setButtonColors(origHex: string) {
		// this.textColor = this.hexToHSL(origHex);
		// this.bgColor = this.findComplimentaryColor(this.textColor);
		// this.bgColor = 'whitesmoke';
	}

	// findComplimentaryColor(origHsl: string): string {
	// 	const origSplit = origHsl.split(',');
	// 	const hue = origSplit[0].substr(origHsl.indexOf('(') + 1);
	// 	let compliment = +hue + 128;
	// 	if (compliment > 255) compliment -= 255;
	// 	return 'hsl(' + compliment + ',' + origSplit.slice(1).join();
	// }

	hexToHSL(hexStr: string) {
		// Convert hex to RGB first
		let rVal = 0, gVal = 0, bVal = 0;
		let rStr = '0x', gStr = '0x', bStr = '0x';
		if (hexStr.length === 4) {
			rStr += hexStr[1] + hexStr[1];
			gStr += hexStr[2] + hexStr[2];
			bStr += hexStr[3] + hexStr[3];
		} else if (hexStr.length === 7) {
			rStr += hexStr[1] + hexStr[2];
			gStr += hexStr[3] + hexStr[4];
			bStr += hexStr[5] + hexStr[6];
		}
		// Then to HSL
		rVal = <any>rStr / 255;
		gVal = <any>gStr / 255;
		bVal = <any>bStr / 255;
		const cmin = Math.min(rVal, gVal, bVal);
		const cmax = Math.max(rVal, gVal, bVal);
		const delta = cmax - cmin;
		let h = 0, s = 0, l = 0;

		if (delta === 0) h = 0;
		else if (cmax === rVal) h = ((gVal - bVal) / delta) % 6;
		else if (cmax === gVal) h = (bVal - rVal) / delta + 2;
		else h = (rVal - gVal) / delta + 4;

		h = Math.round(h * 60);

		if (h < 0) h += 360;

		l = (cmax + cmin) / 2;
		s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
		s = +(s * 100).toFixed(1);
		l = +(l * 100).toFixed(1);

		return 'hsl(' + h + ',' + s + '%,' + l + '%)';
	}
}

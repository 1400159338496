import { Component, Input, OnInit } from '@angular/core';
import { NavigationData, NavigationService } from 'src/app/services/navigation.service';

import { NavBlockInfo } from '../../models/nav-block-info.model';

@Component({
	selector: 'auto-minimap',
	templateUrl: './minimap.component.html',
	styleUrls: ['./minimap.component.scss']
})
export class MinimapComponent implements OnInit {

	@Input() textColor: string;
	@Input() bgColor: string;
	// the "grid-wrapper" that is moved around
	gridWrapper: HTMLElement;

	// Contains info on grid elements from nav-grid
	gridInfo: { grid: any, tiles: NavBlockInfo[] };
	currentTarget: NavigationData;

	minimapActive = false;

	constructor(private navService: NavigationService) { }

	ngOnInit(): void {
		this.gridWrapper = document.getElementById('mini-grid-wrapper');
		this.navService.gridInfo.subscribe((info) => this.setGridSize(info));
		this.navService.currentTarget.subscribe((target) => this.moveGridWrapper(target));
		console.log(this.textColor);
		console.log(this.bgColor);
	}

	navigate(target: NavigationData) {
		console.log(target);
		if (target !== this.currentTarget) this.navService.requestTile(target.coords);
	}

	toggleMinimap(state?: boolean) {
		if (state !== undefined) this.minimapActive = state;
		else { this.minimapActive = !this.minimapActive; }
	}

	moveGridWrapper(target: NavigationData) {
		this.currentTarget = target;
		this.gridWrapper.style.top = `calc(50% - (${target.coords.y} * 5vh))`;
		this.gridWrapper.style.left = `calc(50% - (${target.coords.x} * 5vw))`;
	}

	setGridSize(info: { grid: any, tiles: NavBlockInfo[] }) {
		this.gridInfo = info;
	}

}

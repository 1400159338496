import { Component, Input, OnInit } from '@angular/core';

import { Member } from '../models/member.model';
import { NavBlockInfo, Tile } from '../models/nav-block-info.model';
import { johnathan } from '../../../assets/configuration/global-config'

@Component({
	selector: 'auto-member',
	templateUrl: './member.component.html',
	styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit, Tile {

	@Input() info: NavBlockInfo;

	member: Member = johnathan;

	constructor() {}

	ngOnInit() {}

}
